import { Suspense, useEffect } from "react"
import { Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Layout from "components/common/Layout"
import { Result } from "antd"
import SuspenseComponent from "./SuspenseComponent"
import pageList from "./PageList"
import { useStore } from "components/ui"
import { useTranslation } from "react-i18next"
import { apiUser } from "api"

// import Login from "pages/Authen/Login"
import LoginSSO from "pages/Authen/LoginSSO"
import menus from "pages/Authen/menus"
// import ForgotPassword from "pages/Authen/ForgotPassword"
// import ResetPassword from "pages/Authen/ResetPassword"
// import FirstLogin from "pages/Authen/FirstLogin"
// import OTPConfirm from "pages/Authen/OTPConfirm"

function ProtectedRoute() {
  return (
    <Layout>
      <Suspense fallback={<SuspenseComponent />}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}

async function init(setAuth) {
  const _userInfo = await apiUser.getProfile()
  let baseData = {
    user: _userInfo?.data
  }
  setAuth(baseData)
}

export default function Routers() {
  const { permission, access_token, setAuth } = useStore()
  const tabId = sessionStorage.tabID

  useEffect(() => {
    if (access_token && tabId) {
      init(setAuth)
    }
  }, [])

  return (
    <Router>
      <Routes>
        {access_token && tabId ? (
          <>
            <Route element={<ProtectedRoute />}>
              {pageList().map(({ Element, code, path, objectCode, method, resource }, key) => {
                const item = menus?.find((i) => i.code === code)
                const actionPermission = permission[resource] || {}
                if (actionPermission["GET"] || resource === "root_by_pass") {
                  return (
                    <Route
                      path={path}
                      key={key}
                      element={
                        <Element
                          tabs={item?.tab}
                          actionPermission={actionPermission}
                          objectCode={objectCode}
                          method={method}
                        />
                      }
                    />
                  )
                }
              })}
              <Route path="*" element={<NotLoadAuthorization />} />
            </Route>
          </>
        ) : (
          <Route element={<Outlet />}>
            <Route path="*" element={<LoginSSO />} />
            {/* <Route path="first-login" element={<FirstLogin />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="confirm" element={<OTPConfirm />} />
            <Route path="reset-password" element={<ResetPassword />} /> */}
          </Route>
        )}
      </Routes>
    </Router>
  )
}

const NotLoadAuthorization = () => {
  const { t } = useTranslation()
  return (
    <div className="__content">
      <Result
        status="404"
        title="404"
        subTitle={t("294_1860")}
        // extra={<Link to="/">Back Home</Link>}
      />
    </div>
  )
}

const fake = [
  {
    id: "a4074ca6-b1fd-410d-ae79-1fedd706faef",
    language_code: "af",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "b321335a-ca9b-4ac1-9950-eaee9c22ba88",
    language_code: "ar",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "e2128e65-2c78-4b15-8ddd-a8e22a2bebef",
    language_code: "az",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "3cbef6df-52ed-48b1-b11d-7bf81e0af3f2",
    language_code: "bg",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "057182a3-5296-4348-a83d-71dfe9696d07",
    language_code: "bn",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "239aeeb1-23a0-488e-a6c5-f90371794d6a",
    language_code: "ca",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "6702ba25-daa7-4374-8c01-e4e68faa554f",
    language_code: "cs",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "448a4345-e2e5-49d1-a5b0-7eeb35de8ca0",
    language_code: "cy",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "24f0ab8f-4cb3-4b55-8898-20da4d769259",
    language_code: "da",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "e1590d28-32a2-42b9-a256-f131595d866e",
    language_code: "de",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "74258e60-53e7-4e86-8181-07387ba7345c",
    language_code: "el",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "af5c58f7-4761-43b4-8bc9-83c55956088b",
    language_code: "en",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "fbac0552-ebba-4b6e-856d-3f6ae2ddb598",
    language_code: "es",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "46d25699-d95c-4919-8e9c-aaa344c385ca",
    language_code: "et",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  },
  {
    id: "00643f55-8baf-4ca6-b3d1-b53e3f5b9925",
    language_code: "fa",
    translation_content: "Image",
    file_element_id: "3c421e27-aed1-448c-b061-ba5bc006972f",
    properties: {
      images: ["http://localhost/storage/da5cdec7-d303-4cb6-82a7-7fadb1d926d7.png"],
      original: true
    },
    file_element: {
      id: "3c421e27-aed1-448c-b061-ba5bc006972f",
      element_id: "23:119",
      file_page_id: "4572aad4-9cbc-4390-8208-24c24701f160",
      file_page: {
        id: "4572aad4-9cbc-4390-8208-24c24701f160",
        page_id: "23:100",
        page_name: "003"
      }
    }
  }
]

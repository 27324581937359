// Importing necessary components and styles from Ant Design and other modules
import { ConfigProvider, notification } from "antd"
import { StyleProvider } from "@ant-design/cssinjs"
import useStore, { StoreProvider } from "components/ui/Context"
import Routes from "components/Router"
import "utils/i18n"
import "./App.scss"
import { useEffect } from "react"
import { changeLanguage } from "./utils/i18n"
import { getI18nextLng } from "lib/cookie"

// Configuring default notification settings
notification.config({
  top: 0, // Notification placement from the top of the screen
  duration: 3 // Notification display duration in seconds
})

// Main component representing the entire application
function App() {
  const { access_token } = useStore()

  useEffect(() => {
    if (access_token) {
      changeLanguage(getI18nextLng())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // Ant Design ConfigProvider for global configuration settings
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1C3F38", // Customizing the primary color for tokens
          borderRadius: 4, // Customizing the border radius for tokens
          fontFamily: "Inter" // Customizing the font family for tokens
        }
      }}
    >
      {/* Ant Design StyleProvider for customizing CSS-in-JS styles */}
      <StyleProvider hashPriority="high">
        {/* Custom StoreProvider for managing global state using React context */}
        <StoreProvider>
          {/* Component rendering application routes */}
          <Routes />

          {/* Placeholder for mounting modals outside the normal component hierarchy */}
          <div id="modal-root"></div>
        </StoreProvider>
      </StyleProvider>
    </ConfigProvider>
  )
}

// Exporting the App component as the default export
export default App

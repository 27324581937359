import { lazy } from "react"
import { RESOURCE_KEY } from "lib/Const"

// const Example = lazy(() => import("pages/Example"))
const Dashboard = lazy(() => import("pages/Dashboard"))
const Notification = lazy(() => import("pages/Notification"))
const DetailNotification = lazy(() => import("pages/Notification/DetailNotification"))
const UserProfile = lazy(() => import("pages/UserProfile"))
const ActivityLogs = lazy(() => import("pages/ActivityLogs"))
const ViewLog = lazy(() => import("pages/ActivityLogs/ViewLog"))
const Security = lazy(() => import("pages/UserProfile/Security"))
const PersonalUsageHistory = lazy(() => import("pages/UserProfile/PersonalUsageHistory"))
const Organization = lazy(() => import("pages/Organization"))
const CreateOrganization = lazy(() => import("pages/Organization/FormCreate"))
const AccessLimit = lazy(() => import("pages/Organization/OrganizationSetting/AccessLimit"))
const PasswordPolicy = lazy(() => import("pages/Organization/OrganizationSetting/PasswordPolicy"))
const OTPManagement = lazy(() => import("pages/Organization/OrganizationSetting/OTPManagement"))
const Policy = lazy(() => import("pages/Policy"))
const CreatePolicy = lazy(() => import("pages/Policy/FormCreate"))
const GroupUser = lazy(() => import("pages/GroupUser"))
const CreateGroupUser = lazy(() => import("pages/GroupUser/FormCreate"))
const CreateGroupEndUser = lazy(() => import("pages/GroupUser/FormCreateGroupEndUser"))
const Resource = lazy(() => import("pages/Resource"))
const CreateResource = lazy(() => import("pages/Resource/FormCreate"))
const User = lazy(() => import("pages/User"))
const CreateUser = lazy(() => import("pages/User/FormCreate"))
const EndUser = lazy(() => import("pages/EndUser"))
const CreateEndUser = lazy(() => import("pages/EndUser/FormCreate"))
const Application = lazy(() => import("pages/Application"))
const DetailApplication = lazy(() => import("pages/Application/FormCreate"))
const GroupPermission = lazy(() => import("pages/GroupPermission"))
const CreateGroupPermission = lazy(() => import("pages/GroupPermission/FormCreate"))
const RBACGroupPermission = lazy(() => import("pages/RBACGroupPermission"))
const CreateRBACGroupPermission = lazy(() => import("pages/RBACGroupPermission/FormCreate"))
const RBACPermission = lazy(() => import("pages/RBACPermission"))
const CreateRBACPermission = lazy(() => import("pages/RBACPermission/FormCreate"))
const IdentityProvider = lazy(() => import("pages/IdentityProvider"))
const CreateIdentityProvider = lazy(() => import("pages/IdentityProvider/FormCreate"))
const Vehicle = lazy(() => import("pages/VehicleManagement"))
const UpdateVehicleManagement = lazy(
  () => import("pages/VehicleManagement/UpdateVehicleManagement")
)
const ViewVehiclePage = lazy(() => import("pages/VehicleManagement/ViewVehiclePage"))

//____ListPage
function pageList(__role) {
  return [
    {
      Element: UserProfile,
      path: "/profile",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BY_PASS
    },
    {
      Element: ActivityLogs,
      path: "/activity-logs",
      code: "ACTIVITY_LOGS_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BY_PASS
    },
    {
      path: "activity-logs/:log_id",
      Element: ViewLog,
      code: "ACTIVITY_LOGS_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BY_PASS
    },
    {
      Element: Security,
      path: "/profile/security",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BY_PASS
    },
    {
      Element: PersonalUsageHistory,
      path: "/profile/personal-data-history",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_ADMIN_USER
    },
    {
      Element: Organization,
      path: "/organizations",
      code: "ORGANIZATION_CONTROLLER",
      objectCode: "organization",
      resource: RESOURCE_KEY.ROOT_IDM_ORGANIZATION
    },
    {
      Element: CreateOrganization,
      path: "/organizations/create",
      code: "ORGANIZATION_CONTROLLER",
      objectCode: "organization",
      method: "create",
      resource: RESOURCE_KEY.ROOT_IDM_ORGANIZATION
    },
    {
      Element: CreateOrganization,
      path: "/organizations/update/:id",
      code: "ORGANIZATION_CONTROLLER",
      objectCode: "organization",
      method: "update",
      resource: RESOURCE_KEY.ROOT_IDM_ORGANIZATION
    },
    {
      Element: CreateOrganization,
      path: "/organizations/detail/:id",
      code: "ORGANIZATION_CONTROLLER",
      objectCode: "organization",
      method: "update",
      resource: RESOURCE_KEY.ROOT_IDM_ORGANIZATION
    },
    {
      Element: AccessLimit,
      path: "/organizations/update/:id/access-limit",
      code: "ORGANIZATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_IDM_ORGANIZATION
    },
    {
      Element: PasswordPolicy,
      path: "/organizations/update/:id/password-policy",
      code: "ORGANIZATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_IDM_ORGANIZATION
    },
    {
      Element: OTPManagement,
      path: "/organizations/update/:id/otp",
      code: "ORGANIZATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_IDM_ORGANIZATION
    },
    {
      Element: Policy,
      path: "/policies",
      code: "POLICY_CONTROLLER",
      objectCode: "policy",
      resource: RESOURCE_KEY.ROOT_POLICY
    },
    {
      Element: CreatePolicy,
      path: "/policies/create",
      code: "POLICY_CONTROLLER",
      objectCode: "policy",
      resource: RESOURCE_KEY.ROOT_POLICY
    },
    {
      Element: CreatePolicy,
      path: "/policies/update/:id",
      code: "POLICY_CONTROLLER",
      objectCode: "policy",
      resource: RESOURCE_KEY.ROOT_POLICY
    },
    {
      Element: GroupUser,
      path: "/groups",
      code: "GROUP_CONTROLLER",
      objectCode: "group",
      resource: RESOURCE_KEY.ROOT_GROUP
    },
    {
      Element: CreateGroupUser,
      path: "/groups/create",
      code: "GROUP_CONTROLLER",
      objectCode: "group",
      method: "create",
      resource: RESOURCE_KEY.ROOT_GROUP
    },
    {
      Element: CreateGroupUser,
      path: "/groups/update/:id",
      code: "GROUP_CONTROLLER",
      objectCode: "group",
      method: "update",
      resource: RESOURCE_KEY.ROOT_GROUP
    },
    {
      Element: CreateGroupUser,
      path: "/groups/detail/:id",
      code: "GROUP_CONTROLLER",
      objectCode: "group",
      method: "update",
      resource: RESOURCE_KEY.ROOT_GROUP
    },
    // GroupEndUser
    {
      Element: CreateGroupEndUser,
      path: "/groups/end-user/create",
      code: "GROUP_CONTROLLER",
      objectCode: "group",
      method: "create",
      resource: RESOURCE_KEY.ROOT_GROUP
    },
    {
      Element: CreateGroupEndUser,
      path: "/groups/end-user/update/:id",
      code: "GROUP_CONTROLLER",
      objectCode: "group",
      method: "update",
      resource: RESOURCE_KEY.ROOT_GROUP
    },
    {
      Element: CreateGroupEndUser,
      path: "/groups/end-user/detail/:id",
      code: "GROUP_CONTROLLER",
      objectCode: "group",
      method: "update",
      resource: RESOURCE_KEY.ROOT_GROUP
    },
    {
      Element: Resource,
      path: "/resources",
      code: "RESOURCE_CONTROLLER",
      objectCode: "resource",
      resource: RESOURCE_KEY.ROOT_RESOURCE
    },
    {
      Element: CreateResource,
      path: "/resources/create",
      code: "RESOURCE_CONTROLLER",
      objectCode: "resource",
      resource: RESOURCE_KEY.ROOT_RESOURCE
    },
    {
      Element: CreateResource,
      path: "/resources/update/:id",
      code: "RESOURCE_CONTROLLER",
      objectCode: "resource",
      resource: RESOURCE_KEY.ROOT_RESOURCE
    },
    {
      Element: GroupPermission,
      path: "/permissions/roles",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_ROLE
    },
    {
      Element: CreateGroupPermission,
      path: "/permissions/roles/create",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_ROLE
    },
    {
      Element: CreateGroupPermission,
      path: "/permissions/roles/update/:id",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_ROLE
    },
    {
      Element: User,
      path: "/admin_user",
      code: "ADMIN_USER_CONTROLLER",
      objectCode: "user",
      resource: RESOURCE_KEY.ROOT_DASHBOARD
    },
    {
      Element: CreateUser,
      path: "/admin_user/create",
      code: "ADMIN_USER_CONTROLLER",
      objectCode: "user",
      method: "create",
      resource: RESOURCE_KEY.ROOT_DASHBOARD
    },
    {
      Element: CreateUser,
      path: "/admin_user/update/:id",
      code: "ADMIN_USER_CONTROLLER",
      objectCode: "user",
      method: "update",
      resource: RESOURCE_KEY.ROOT_DASHBOARD
    },
    {
      Element: CreateUser,
      path: "/admin_user/detail/:id",
      code: "ADMIN_USER_CONTROLLER",
      objectCode: "user",
      method: "update",
      resource: RESOURCE_KEY.ROOT_ADMIN_USER
    },
    {
      Element: EndUser,
      path: "/enduser",
      code: "ENDUSER_CONTROLLER",
      objectCode: "end_user",
      resource: RESOURCE_KEY.ROOT_END_USER
    },
    {
      Element: CreateEndUser,
      path: "/enduser/create",
      code: "ENDUSER_CONTROLLER",
      objectCode: "end_user",
      method: "create",
      resource: RESOURCE_KEY.ROOT_END_USER
    },
    {
      Element: CreateEndUser,
      path: "/enduser/update/:id",
      code: "ENDUSER_CONTROLLER",
      objectCode: "end_user",
      method: "update",
      resource: RESOURCE_KEY.ROOT_END_USER
    },
    {
      Element: CreateEndUser,
      path: "/enduser/detail/:id",
      code: "ENDUSER_CONTROLLER",
      objectCode: "end_user",
      method: "update",
      resource: RESOURCE_KEY.ROOT_END_USER
    },
    {
      Element: Application,
      path: "/applications",
      code: "APPLICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_APPLICATION
    },
    {
      Element: DetailApplication,
      path: "/applications/view/:id",
      code: "APPLICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_APPLICATION
    },
    // {
    //   Element: Vehicle,
    //   path: "/vehicles",
    //   code: "VEHICLE_CONTROLLER",
    //   resource: RESOURCE_KEY.ROOT_VEHICLE
    // },
    // {
    //   path: "/vehicles/action",
    //   Element: UpdateVehicleManagement,
    //   resource: RESOURCE_KEY.ROOT_VEHICLE
    // },
    // {
    //   path: "/vehicles/view",
    //   Element: ViewVehiclePage,
    //   resource: RESOURCE_KEY.ROOT_VEHICLE
    // },
    {
      Element: IdentityProvider,
      path: "/identity_provider",
      code: "IDENTITY_PROVIDER_CONTROLLER",
      objectCode: "identity_provider",
      resource: RESOURCE_KEY.ROOT_IDENTITY_PROVIDER
    },
    {
      Element: CreateIdentityProvider,
      path: "/identity_provider/create",
      code: "IDENTITY_PROVIDER_CONTROLLER",
      objectCode: "identity_provider",
      method: "create",
      resource: RESOURCE_KEY.ROOT_IDENTITY_PROVIDER
    },
    {
      Element: CreateIdentityProvider,
      path: "/identity_provider/update/:id",
      code: "IDENTITY_PROVIDER_CONTROLLER",
      objectCode: "identity_provider",
      method: "update",
      resource: RESOURCE_KEY.ROOT_IDENTITY_PROVIDER
    },
    {
      Element: CreateIdentityProvider,
      path: "/identity_provider/detail/:id",
      code: "IDENTITY_PROVIDER_CONTROLLER",
      objectCode: "identity_provider",
      method: "update",
      resource: RESOURCE_KEY.ROOT_IDENTITY_PROVIDER
    },
    {
      Element: Notification,
      path: "/notification",
      code: "NOTIFICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BY_PASS
    },
    {
      Element: DetailNotification,
      path: "/notification/:id",
      code: "NOTIFICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BY_PASS
    },
    {
      Element: Dashboard,
      path: "/dashboards",
      code: "HOME_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_DASHBOARD
    },
    {
      Element: RBACPermission,
      path: "/permissions/rbac-permission",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_RBAC_PERMISSION
    },
    {
      Element: CreateRBACPermission,
      path: "/permissions/rbac-permission/create",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_RBAC_PERMISSION
    },
    {
      Element: CreateRBACPermission,
      path: "/permissions/rbac-permission/update/:id",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_RBAC_PERMISSION
    },
    {
      Element: RBACGroupPermission,
      path: "/permissions/rbac-group-permission",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_RBAC_GROUP_PERMISSION
    },
    {
      Element: CreateRBACGroupPermission,
      path: "/permissions/rbac-group-permission/create",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_RBAC_GROUP_PERMISSION
    },
    {
      Element: CreateRBACGroupPermission,
      path: "/permissions/rbac-group-permission/update/:id",
      code: "ROLE_CONTROLLER",
      objectCode: "group_permission",
      resource: RESOURCE_KEY.ROOT_RBAC_GROUP_PERMISSION
    }
  ]
}

export default pageList
